<template>
    <div class="numeric">
        {{ formatedValue }}
    </div>
</template>

<script>
import numeral from 'numeral'

export default {
  computed: {
    formatedValue() {
      console.log(this.params.value)

      return numeral(this.params.value).format('00:00')
    },
  },
}
</script>

<style scoped>
.numeric {
  text-align: right;
}
</style>
